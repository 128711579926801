import { VApplication } from "../../../../../application";
import { LoggerModule } from "@vlinder-web/logger-module-react";
import { TriggerPageService } from "../../../../../services";

const MODULE_NAME = "profile.machines.states.getPage";
const log: any = LoggerModule?.getInstance();

const getSrv = () => {
  const app = VApplication.getInstance();
  return app.getSync("services.TriggerPageService") as TriggerPageService;
};

export const getPageContent = async (context: any, event: any) => {
  const currentState = "GET_PAGE_CONTENT";
  const method = "getPageContent";

  return new Promise(async (resolve, reject) => {
    try {
      log.blue(MODULE_NAME, "context: ", context, "event: ", event);
      const srv = getSrv();
      const _getArgs = () => {
        let _args: any = {};
        if (event?.type === "START") {
          _args = context["GET_PAGE_CONTENT"];
        }
        // if (event?.type === "TRIGGER_REFRESH") {
        //   _args = {
        //     offset: event?.offset,
        //     size: event?.size,
        //     where: event?.where ?? [],
        //   };
        // }
        console.log("getPageContent args =>", _args);
        return _args;
      };
      const content = await srv.getPageContent(_getArgs());

      if (content) {
        return resolve({
          key: "RENDER_PAGE",
          value: {
            ...(content || {}),
          },
        });
      } else {
        const msg = "Page content not found";
        log.error(MODULE_NAME, `error in ${method}, msg: ${msg}`);
        return reject({
          key: "RENDER_PAGE_ERROR",
          value: msg,
        });
      }
    } catch (err: any) {
      log.error(MODULE_NAME, `error in ${method}, msg: ${err?.message}`);
      let _msg = err?.message?.replace('GraphQL error:','');
      return reject({
        key: "RENDER_PAGE_ERROR",
        value: _msg,
      });
    }
  });
};

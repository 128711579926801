import { VApplication } from "../../../../../application";
import { LoggerModule } from "@vlinder-web/logger-module-react";
import { TriggerPageService } from "../../../../../services";

const MODULE_NAME = "Trigger.machines.states.triggerCyclone";
const log: any = LoggerModule?.getInstance();

const getSrv = () => {
  const app = VApplication.getInstance();
  return app.getSync("services.TriggerPageService") as TriggerPageService;
};

export const triggerCyclone = async (context: any, event: any) => {
  const currentState = "TRIGGER_CYCLONE";
  const method = "triggerCyclone";

  return new Promise(async (resolve, reject) => {
    try {
      log.blue(MODULE_NAME, "context: ", context, "event: ", event);
      const srv = getSrv();
      console.log("In machine : ", event);
      const content = await srv.triggerCyclone(event);
      if (content) {
        return resolve({
          key: "TRIGGER_CYCLONE_SUCCESS",
          value: {
            ...(content || {}),
          },
        });
      } else {
        const msg = "Page content not found";
        log.error(MODULE_NAME, `error in ${method}, msg: ${msg}`);
        return reject({
          key: "TRIGGER_CYCLONE_FAILED",
          value: msg,
        });
      }
    } catch (err: any) {
      log.error(MODULE_NAME, `error in ${method}, msg: ${err?.message}`);
      return reject({
        key: "TRIGGER_CYCLONE_FAILED",
        value: err?.message,
      });
    }
  });
};
